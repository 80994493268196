<template>
  <div class="instant-survey-page-statistic">
    <a-row :gutter="device.mobile ? 0 : 24">
      <a-col :lg="6" :xs="24">
        <div class="statistic-sidebar">
          <div class="sidebar-label">{{ instantSurveyRepliesData.length }} 則回覆</div>
          <a-table
            :dataSource="usersData"
            :columns="tableColumns"
            size="small"
            :pagination="false"
            :scroll="{ x: 'max-content' }"
          >
            <template #bodyCell="{ column, text, record }">
              <template v-if="column.key === 'replied'">
                <span :style="{ color: green[5] }" v-if="record.replied">已回覆</span>
                <span :style="{ color: red[5] }" v-else>未回覆</span>
              </template>
            </template>
          </a-table>
        </div>
      </a-col>

      <a-col :lg="18" :xs="24">
        <div class="statistic-main">
          <div
            v-for="item in instantSurveyData.contents"
            class="survey-item"
          >
            <div class="item-name">{{ item.name }}</div>
            <div class="item-stat">
              <template v-if="item.type == 'radio' || item.type == 'checkbox'">
                <BarChart
                  :chartData="generateChartData(surveyContentsStat[item.name].stat)"
                  :options="chartOptions"
                  :style="{ height: '200px' }"
                />
              </template>

              <template v-if="item.type == 'text-field'">
                <div class="stat-text-list">
                  <div
                    class="text-item"
                    v-for="text in generateChartData(surveyContentsStat[item.name].stat).labels"
                  >
                    {{ text }}
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { createDeviceDetector } from 'next-vue-device-detector'
import { green, red } from '@ant-design/colors'
import api from '@/apis'
import { BarChart } from 'vue-chart-3'
import { Chart, registerables } from "chart.js"

Chart.register(...registerables);
const chartOptions = {
  plugins: {
    legend: false
  },
}

const tableColumns = [
  {
    title: '學員編號',
    dataIndex: 'member_id',
    key: 'member_id',
  },
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '回覆',
    dataIndex: 'replied',
    key: 'replied',
  }
]

export default defineComponent({
  components: {
    BarChart
  },
  setup() {
    const route = useRoute();
    const device = createDeviceDetector();

    /* Data */
    const usersData = ref([]);
    const instantSurveyData = ref({});
    const instantSurveyRepliesData = ref([]);

    /* Stat Data */
    const calculating = ref(true);
    const surveyContentsStat = ref({});

    /* Api */
    const getUserMembers = (async () => {
      const response = await api.v1.course.findMembers('user', {
        course_id: route.params.course_id
      });
      usersData.value = response.data.result;
    });

    const getInstantSurveyData = (async () => {
      const response = await api.v1.course.findOneInstantSurvey(route.params.course_id, route.params.id);
      instantSurveyData.value = response.data.result;
    });

    const getInstantSurveyReplyData = (async () => {
      const response = await api.v1.course.findInstantSurveyRepliedList(route.params.course_id, route.params.id);
      instantSurveyRepliesData.value = response.data.result;
      console.log(instantSurveyRepliesData.value);
    });

    /* Function */
    const calculateSurveyContents = (async () => {
      // 定好 contents schema
      instantSurveyData.value.contents.forEach(content => {
        const name = content.name;
        const type = content.type;
        let stat = {};
        
        // 找出 key
        if (type == 'radio' || type == 'checkbox') {
          stat = {};
          content.selection_items.forEach(selectItem => {
            stat[selectItem.label] = 0;
          });
        }

        surveyContentsStat.value[name] = {
          type: type,
          stat: stat
        };
      });

      // 統計回覆
      instantSurveyRepliesData.value.forEach(replyItem => {
        replyItem.contents.forEach(content => {
          const statItem = surveyContentsStat.value[content.name]['stat'];

          if (content.type == 'radio') {
            statItem[content.value] += 1;
          }
          else if (content.type == 'checkbox') {
            content.value.forEach(contentValue => {
              statItem[contentValue] += 1
            });
          }
          else if (content.type == 'text-field') {
            if (!Object.keys(statItem).includes(content.value)) {
              statItem[content.value] = 1
            } else {
              statItem[content.value] += 1
            }
          }
        });
      });

      calculating.value = false;

      console.log(surveyContentsStat.value);
    });

    /* Mounted */
    onMounted(async () => {
      await getUserMembers();
      await getInstantSurveyData();
      await getInstantSurveyReplyData();
      calculateSurveyContents();

      // 紀錄學員回覆
      const repliedUserIds = instantSurveyRepliesData.value.map(item => item.user_id);
      console.log(repliedUserIds);
      usersData.value.forEach(user => {
        user.replied = false;
        if (repliedUserIds.includes(user.uid)) user.replied = true;
      });
    });

    return {
      device,

      /* Data */
      usersData,
      instantSurveyData,
      instantSurveyRepliesData,
      surveyContentsStat,

      /* Chart */
      chartOptions,
      
      /* Table */
      tableColumns,

      /* Antd */
      green,
      red
    }
  },
  methods: {
    /* Chart Data Func */
    generateChartData(statObject) {
      let data = {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: [],
          borderColor: [],
          borderWidth: 1
        }]
      }
      const backgroundColor = 'rgba(54, 162, 235, 0.2)';
      const borderColor = 'rgb(54, 162, 235)';
      
      Object.entries(statObject).forEach(item => {
        console.log(item)
        data.labels.push(item[0]);
        data.datasets[0].data.push(item[1]);
        data.datasets[0].backgroundColor.push(backgroundColor);
        data.datasets[0].borderColor.push(borderColor);
      });

      console.log(data)

      return data
    }
  }
})
</script>

<style lang="scss">
.instant-survey-page-statistic {
  .statistic-sidebar {
    height: 100%;
    padding: 0 12px;
    overflow: auto;
    margin-bottom: 24px;

    /* md */
    @media screen and (min-width: 970px) {
      height: calc(100vh - 165px);
      padding: 0 0px;
      margin-bottom: 0;
    }

    .sidebar-label {
      color: #424242;
      font-size: 1rem;
      margin-bottom: 16px;
      text-align: right;
    }
  }

  .statistic-main {
    height: 100%;
    padding: 0 12px;
    overflow: auto;

    /* md */
    @media screen and (min-width: 970px) {
      height: calc(100vh - 165px);
      padding: 0 50px;
    }
  }
  
  .survey-item {
    border: 1.5px solid #E0E0E0;
    border-radius: 4px;
    margin-bottom: 20px;

    .item-name {
      color: #212121;
      font-size: 1.1rem;
      font-weight: 500;
      border-bottom: 1.5px solid #E0E0E0;
      padding: 10px 20px;
    }

    .item-stat {
      padding: 20px;
    }

    .stat-text-list {
      .text-item {
        
      }
    }
  }
}
</style>